<template>
  <div>
    <ul class="tabs lg-up">
      <TabsButton v-for="item in items"
                  v-bind:key="item.id"
                  :active-tab-id="activeTabId"
                  @click="clickTab(item.id)"
                  :item="item" />
    </ul>
    <ResponsiveTabs class="xm-down" :items="items" :active-tab-id="activeTabId" @switch-tab="clickTab" />
  </div>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import TabsButton from '@/01-atoms/Tabs-button.vue'
import ResponsiveTabs from '@/02-molecules/Responsive-tabs.vue'

export interface Tab {
  title: string;
  id: string;
  currentPage: number;
  isLoading: boolean;
}

class Props {
  activeTabId?: string
  items?: Array<Tab>
}

@Options({
  name: 'Tabs',
  components: {
    TabsButton,
    ResponsiveTabs
  },
  emits: ['switch-tab']
})
export default class Tabs extends Vue.with(Props) {
  clickTab (id: string) {
    this.$emit('switch-tab', id)
  }
}
</script>
<style lang="scss" scoped>
.tabs {
  background-color: $blue-dark-10;
  border-radius: 10px;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  width: 100%;
}
</style>
