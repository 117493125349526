
import { Options, Vue, prop } from 'vue-class-component'
import TabsButton from '@/01-atoms/Tabs-button.vue'
import ResponsiveTabs from '@/02-molecules/Responsive-tabs.vue'

export interface Tab {
  title: string;
  id: string;
  currentPage: number;
  isLoading: boolean;
}

class Props {
  activeTabId?: string
  items?: Array<Tab>
}

@Options({
  name: 'Tabs',
  components: {
    TabsButton,
    ResponsiveTabs
  },
  emits: ['switch-tab']
})
export default class Tabs extends Vue.with(Props) {
  clickTab (id: string) {
    this.$emit('switch-tab', id)
  }
}
