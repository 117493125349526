
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  items!: object
  activeTabId?: string
}

@Options({
  name: 'Responsive-tabs',
  emits: ['switch-tab']
})
export default class ResponsiveTabs extends Vue.with(Props) {
  selectedTab = this.activeTabId

  clickTab (ev: Event) {
    const select: any = ev.target
    this.$emit('switch-tab', select.value)
  }
}
