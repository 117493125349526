
import { Options, Vue } from 'vue-class-component'
import { Tab } from '@/02-molecules/Tabs.vue'

class Props {
  item!: Tab
  activeTabId!: string
}

@Options({
  name: 'Tabs-button'
})

export default class TabsButton extends Vue.with(Props) {
  get buttonClass () {
    const classes = {}
    classes['tabs__button'] = true
    if (this.item['id'] === this.activeTabId) {
      classes['is-active'] = true
    }
    return classes
  }
}
