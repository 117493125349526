import axios from 'axios'

export const getQrToken = async () => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: '/uploads/token',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const getQrFile = async (token: string) => {
  const bearer = window.localStorage.getItem('token')
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/uploads/files/token/${token}`,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${bearer}`
    },
    data: {}
  })
}

export const postQrFile = async (token: string, file: any) => {
  return axios({
    method: 'post',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/uploads/files/token/${token}`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: file
  })
}

export const checkQrToken = async (token: string) => {
  return axios({
    method: 'get',
    baseURL: process.env.VUE_APP_API_URL,
    url: `/uploads/token/${token}`,
    headers: {
      'Accept': 'application/json'
    },
    data: {}
  })
}
