
import { Options, Vue } from 'vue-class-component'
import QrcodeVue from 'qrcode.vue'
import variables from '@/scss/js-variables.scss'
import Button from '@/01-atoms/Button.vue'
import { getQrFile, getQrToken } from '@/api/uploads/qr-api'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

class Props {
  loadQr?: boolean
  type!: 'default' | 'cns'
}

@Options({
  name: 'Qr-code',
  components: {
    LoadingDots,
    Button,
    QrcodeVue
  },
  emits: ['add-qr-file']
})

/**
 * QR Code Generator
 *
 */
export default class QrCode extends Vue.with(Props) {
  qrCodeColor = variables.blueDark
  url = 'https://lightbulb.lu/'
  isLoading = false
  isActive = false

  fileCheckerTimer: any

  beforeMount () {
    this.loadQr && this.getToken()
  }

  clickQrButton () {
    this.isLoading = true

    this.getToken()
  }

  errorHandlerService = inject(ErrorHandlerKey)
  getToken () {
    this.isLoading = true
    getQrToken()
      .then((response) => {
        const token = response.data.token
        this.createQrUrl(token)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  createQrUrl (token: string) {
    this.url = `${window.location.origin}/qr/${this.type}/${token}`
    this.isLoading = false
    this.isActive = true

    console.log(this.url)

    this.checkForFileUpload(token)
  }

  checkForFileUpload (token: string) {
    this.fileCheckerTimer = setInterval(() => {
      getQrFile(token)
        .then((response) => {
          clearInterval(this.fileCheckerTimer)
          this.$emit('add-qr-file', response.data, this.type)
          this.isActive = false
        })
    }, 3000)
  }

  beforeUnmount () {
    clearInterval(this.fileCheckerTimer)
  }
}
