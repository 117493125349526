<template>
  <li class="tabs__item">
    <button :class="buttonClass">
      <span>{{ item.title }}</span>
      <span class="tabs__notification" v-if="item.notifications >= 1">{{ item.notifications }}</span>
    </button>
  </li>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Tab } from '@/02-molecules/Tabs.vue'

class Props {
  item!: Tab
  activeTabId!: string
}

@Options({
  name: 'Tabs-button'
})

export default class TabsButton extends Vue.with(Props) {
  get buttonClass () {
    const classes = {}
    classes['tabs__button'] = true
    if (this.item['id'] === this.activeTabId) {
      classes['is-active'] = true
    }
    return classes
  }
}
</script>
<style lang="scss" scoped>
.tabs__item {
  display: inline;
  position: relative;
}

.tabs__button {
  @include brandon-grotesque;
  background-color: transparent;
  border: 0;
  color: $blue-dark;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  padding: 9px 30px;
  text-transform: uppercase;
  min-height: 45px;

  &:hover {
    cursor: pointer;
  }

  &.is-active {
    @include brandon-grotesque-bold;
    background-color: $blue-dark;
    border-radius: 10px;
    color: white;
  }
}

.tabs__notification {
  @include brandon-grotesque-bold;
  background-color: $red;
  border-radius: 50%;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 27px;
  margin-left: 15px;
  position: relative;
  width: 27px;
}
</style>
