<template>
  <select name="tabs" id="tabs" class="responsive-tabs" @change="clickTab($event)">
    <option v-for="item in items"
            v-bind:key="item.title"
            :value="item.id"
            :selected="activeTabId === item.id">
      {{ item.title }}
    </option>
  </select>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  items!: object
  activeTabId?: string
}

@Options({
  name: 'Responsive-tabs',
  emits: ['switch-tab']
})
export default class ResponsiveTabs extends Vue.with(Props) {
  selectedTab = this.activeTabId

  clickTab (ev: Event) {
    const select: any = ev.target
    this.$emit('switch-tab', select.value)
  }
}
</script>
<style lang="scss" scoped>
$chevron-color: encodecolor($blue-dark);
.responsive-tabs {
  @include brandon-grotesque;
  appearance: none;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Cpath fill='#{$chevron-color}' d='M18.29,9.29,13,14.59,7.71,9.29a1,1,0,0,0-1.42,1.42l6,6a1,1,0,0,0,1.42,0l6-6a1,1,0,0,0-1.42-1.42Z'/%3E%3C/svg%3E") no-repeat 100% 50% / 24px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid $blue-dark;
  border-left: 0;
  border-radius: 0;
  color: $grey-dark;
  display: inline-block;
  font-size: 16px;
  text-align: left;
  padding: 6px 0;
  width: 100%;
}
</style>
